(function($, Drupal, ServiceBus, Topics) {
  if (!Topics.events || !Topics.events.FINDATION_BUTTON_CLICKED || $('js-third-party-tout-findation-cta').length > 0 ) {
    return;
  }
  const isIframe = window.location !== window.parent.location;
  var $iframeFindations = $();
  var loadingFindation = false;
  console.log("Findation ---> ",window.Findation);
  function trackEvent(eName, eCategory, eAction, elabel) {
    site.track.evtLink({
      event_name: eName,
      event_category: eCategory,
      event_action: eAction,
      event_label: elabel
    });
  }

  $(document).on('findation-ready', function(e, data) {
    $(document).trigger('findation-initialised');

    if (data.showWidget) {
      showFindation(data.selector || '');
    } else if (window.Findation && window.Findation.iframe) {
      // When we preload, we need to set iframe here
      $iframeFindations = !$iframeFindations.length ? $(window.Findation.iframe) : $iframeFindations;
    }
  });

  $(document).on('findation-preload', function(e, data) {
    if (!data?.findationKey || !data.selector) {
      return;
    }
    loadFindation(data.findationKey, data.selector, data.options || {}, false);
  });

  $(document).on('findation-load', function(e, data) {
    if (!data?.findationKey || !data.selector) {
      return;
    }
    $iframeFindations.length > 0 ? showFindation(data.selector) : loadFindation(data.findationKey, data.selector, data.options || {}, true);
  });

  function showFindation(positionSelector) {
    trackEvent('widget_opened', 'Findation', 'Widget', 'Opened');
    if (window.Findation && window.Findation.iframe) {
      $iframeFindations = !$iframeFindations.length ? $(window.Findation.iframe) : $iframeFindations;
      $iframeFindations.show();
      if (isIframe) {
        $(positionSelector, window.document).addClass('invisible-elem');
        $(parent.document).find('#' + window.frameElement?.id).addClass('full-page-takeof-iframe');
        $('body', window.document).addClass('bg-transparent');
      }
    }
  }

  function loadFindation(findationKey, positionSelector, options, showWidget) {
    if (!$iframeFindations.length > 0 && !loadingFindation) {
      loadingFindation = true;
      widgetVersion = Drupal?.settings?.globals_variables?.findationWidgetVersion || '1';
      enableCache = Drupal?.settings?.globals_variables?.findationGetScriptCache || false;
      $.ajaxSetup({ cache: enableCache, async: true });
      $.getScript('https://findation.com/javascripts/w-adv-' + widgetVersion + '.js', function() {
        window.Findation = window.Findation || {};
        Findation.widgetEmbedded = function() {
          trackEvent('widget_presented', 'Findation', 'Widget', 'Presented');
        }

        Findation.widgetDidOpen = function() {
          trackEvent('widget_opened', 'Findation', 'Widget', 'Opened');
        };

        Findation.widgetDidClose = function() {
          trackEvent('widget_closed', 'Findation', 'Widget', 'Closed');
          if (isIframe) {
            $(positionSelector).removeClass('invisible-elem');
            $(parent.document).find('#' + window.frameElement?.id).removeClass('full-page-takeof-iframe');
          }
        };

        Findation.onSearchComplete = function(data) {
          trackEvent('searchComplete', 'Findation', 'Widget', 'Shades Entered');
          if (data.matchSuccess) {
            trackEvent('recommendedShade', 'Findation', 'Widget', data.recommendedShade);
          } else {
            trackEvent('no_recommendation', 'Findation', 'Widget', 'Widget No Recommendation');
          }
        };

        Findation.init(
          $(positionSelector)[0],
          findationKey,
          options
        );
        $(document).trigger('findation-ready', {selector: positionSelector, showWidget});
      }, enableCache);
    }
  }
  ServiceBus.on(Topics.events.FINDATION_BUTTON_CLICKED,
    function(payload) {
      if (payload) {
        if (ServiceBus.log) {
          ServiceBus.log({
            message: Topics.events.FINDATION_BUTTON_CLICKED,
            payload: { context: payload }
          });
        }

        if ($iframeFindations.length < 1) {
          loadFindation(payload.findationKey, '.js-third-party-tout-findation-cta', {}, true);
        } else {
          showFindation('');
        }
      }
    }
  );

})(
  jQuery,
  Drupal,
  window.GlobalServiceBus || {},
  window.ServiceBusTopics || {}
);
